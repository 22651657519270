import { defineStore } from 'pinia';

export const useLocationsStore = defineStore('locationsStore', {
  state: () => ({
    list: null,
    item: null,
    pickUpList: null,
    dropOffList: null,
    loading: false,
    active: null,
  }),
  actions: {
    async getList() {
      const { data: list } = await useFetch('/api/v2/locations/pick-up');
      if (list) this.list = list;
    },
    async getPickUpList() {
      const { data: pickUpList } = await useFetch('/api/v2/locations/pick-up?fetchData=min');
      if (pickUpList) this.pickUpList = pickUpList;
    },
    async getDropOffList(id) {
      const { data: dropOffList } = await useFetch(`/api/v2/locations/drop-off/${id}`);
      if (dropOffList) this.dropOffList = dropOffList;
    },
    async getItem(slug) {
      const route = useRoute();
      if (route.name == 'region') {
        const { data: city } = await useFetch(`/api/v2/regions/${slug}`);
        if (city) this.item = city;
      } else {
        const { data: city } = await useFetch(`/api/v2/location_data/${slug}`);
        if (city) this.item = city;
      }
    },
  }
});